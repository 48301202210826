.product-page{
    background: white;
    color: black;
    padding-left: 8%;
    padding-right: 7%;
    padding-bottom: 5%;
    padding-top: 6%;
}

.first-text-product{
    margin-top: 0;
    font-style: normal;
    margin-bottom: 20px;
    font-size: 4vw;
}

.products{
    display: flex;
    justify-content: center;  
    align-items: center;  
}

@media only screen and (max-width:601px){
    .product-page-decor{
        display: none;
    }
    .first-text-product{
        font-size: 6vw;
    }
}