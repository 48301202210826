.contact-page {
    background: #242830;
    color: white;
    text-align: center;
}

/* General Container */
.contact-us-wrapper {
    display: flex;
    flex-direction: row;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 9%;
    border-radius: 15px;
    gap: 6%;
}

/* Left Section Styling */
.left-section {
    width: 47%;
    height: auto;
    padding: 3rem;
    gap: 10%;
    background-color: #1e1f29;
    display: flex;
    flex-direction: column;
    color: white;
}

.quote {
    font-style: italic;
    color: #f8f8f2;
    font-size: 1.2rem;
    margin: 0;
    text-align: center;
    border-left: 4px solid #28C3FF;
    padding-left: 1rem;
}

.gradient-text{
    background: linear-gradient(90deg, #48ffde, #4ae9ff , #fc3ef4 , #00ccf9 ); /* Define your gradient colors */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; /* Ensure text is transparent to show the gradient */
    background-clip: text;
    color: transparent; 
}

.contact-info {
    width: 100%;
    height: auto;
    padding: 0%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.contact-info-header{
    font-style: normal;
    font-weight: 400;
    font-size: 2.5vw;
    margin: 0;
    padding: 0;
    text-align: left;
}

.contact-info-component {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.contact-info-component-val:hover {
    color: #28C3FF;
}

.contact-info-component-header{
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
    margin: 0;
    padding: 0;
}

.contact-info img {
    width: 10%;
    padding-right: 5%;
    height: auto;
    align-self: self-start;
}

.social-links {
    display: flex;
    flex-direction: row;
    gap: 3rem;
}

.social-links img{
    width: 100%;
    height: auto;
}

/* Right Section Styling */
.right-section {
    width: 47%;
    padding: 3rem;
    background-color: #1e1f29;
    color: #f8f8f2;
}

.right-section h2 {
    text-align: center;
    margin-top: 0%;
    margin-bottom: 2rem;
    font-size: 1.8rem;
}

.form-group {
    margin-bottom: 1.5rem;    
    display: flex;
}

.form-group input{
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #44475a;
    border-radius: 5px;
    background-color: #282a36;
    color: white;
    transition: border-color 0.3s ease;
}

.form-group input:focus{
    border-color:  #28C3FF;
    outline: none;
}

.submit-btn {
    display: block;
    width: 100%;
    padding: 0.75rem;
    background-color:  #28C3FF;
    color: #FFF;
    font-size: 1rem;
    font-weight: 400;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    justify-content: center;
}

.submit-btn:hover {
    background-color:#FFFF;
    color: #242830;
}

/* Responsive Design */
@media (max-width: 601px) {
    .contact-us-wrapper {
        flex-direction: column;
        padding-top: 16%;
    }

    .left-section,
    .right-section {
        width: 90%;
        padding: 0.9rem;
    }

    .quote{
        font-size: 1rem;
        padding-left: 0;
    }

    .left-section {
        border-radius: 15px 15px 0 0;
        gap: 1.2rem;
    }
    .right-section {
        border-radius: 0 0 15px 15px;
    }

    .submit-btn{
        font-size: 0.7rem;
    }

    .form-group input{
        font-size: 0.7rem;
    }

    .right-section h2{
        font-size: 1rem;
        font-weight: 400;
    }

    .contact-info img{
        width: 6%;
    }

    .social-links img{
        width: 50%;
    }

    .contact-info-component-header{
        font-size: 5vw;
        padding: 0;
        margin: 0;
    }

    .contact-info-header{
        font-size: 7vw;
    }

    .social-links{
        gap: 0.5rem;
    }

    .contact-info-component-val{
        font-size: 3vw;
        padding: 0;
        margin: 0;
        margin-top: 0.3rem;
    }
}
