.blog-page {
    background: #242830;
    color: white;
    text-align: center;
}

.blog-and-table-of-contents{
    display: flex;
    flex-direction: row;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 9%;
    gap: 4%;
}

.blog-title-and-chapters{
    width: 66%;
}

.blog-title {
    font-style: normal;
    font-weight: bold;
    font-size: 3vw;
    margin: 0;
    padding: 0;
}

.blog-info{
    margin-top: 0%;
    width: 100%;
    flex-direction: row-reverse;
    text-align: right;
}

.blog-date{
    padding-top: 0%;
    padding-right: 2%;
    font-size: 1.2vw;
}

.blog-author{
    padding-top: 0%;
    padding-right: 1%;
    font-size: 1.2vw;
}

.blog-title-img{
    width: 100%;
    height: 20%;
    align-self:center ;
}

.blog-subtitle{
    font-style: normal;
    font-size: 1.2vw;
    font-weight: 100;
    text-align: justify;
    letter-spacing: .1vw;
    line-height: 1.5vw;
}

.table-of-contents {
    width: 30%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 4%;
    border: 1px solid white;   
    border-radius: 15px;
    padding-top: 2%;
}

.table-of-contents-title {
    font-style: normal;
    font-size: 2vw;
    font-weight: 400;
    padding: 0;
    margin: 0;
    margin-bottom: 4%;
}

@media only screen and (max-width:601px) {

    .blog-title-img{
        height: auto;
        width: 100%;
    }

    .blog-and-table-of-contents{
        padding-left: 10% ;
        padding-right: 10%;
        padding-top: 16%;
    }

    .blog-title-and-chapters{
        width: 100%;
    }

    .blog-title{
        font-size: 5vw;
    }

    .blog-date{
        font-size: 2.2vw;
        padding-right: 4%;
    }
    
    .blog-author{
        font-size: 2.2vw;
        padding-right: 2%;
    }

    .blog-subtitle{
        font-size: 3vw;
        line-height: 4vw;
    }

    .table-of-contents{
        display: none;
    }
}