.about-page {
    display: flex;
    flex-direction:row ;
    gap: 10%;
    background: #242830;
    color: white;
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 5%;
    padding-top: 5%;
}

.about-page-image-component {
    width: 40%;
    height: auto;
}

.about-page-image{
    width: 100%;
    height: auto;
}

.about-page-para {
    width: 50%;
    height: auto;
    align-content: center;
}

.first-text-about {
    font-style: normal;
    font-size: 3.2vw;
    font-weight: bold
}

.gradient-text{
    background: linear-gradient(90deg, #48ffde, #4ae9ff , #fc3ef4 , #00ccf9 ); /* Define your gradient colors */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; /* Ensure text is transparent to show the gradient */
    background-clip: text;
    color: transparent; 
}

.second-text-about {
    font-style: normal;
    margin-top: 5%;
    font-size: 1.2vw;
    font-weight: 100;
    letter-spacing: .1vw;
    line-height: 1.5vw;
}

.about-page-decor {
    margin-left: 0;
}

@media only screen and (max-width:601px) {
    .about-page{
        padding-top: 8%;
        text-align: center;
        flex-direction: column-reverse;
    }

    .about-page-decor{
        display: none;
    }

    .about-page-para{
        width: 100%;
    }
    
    .about-page-image-component{
        width : 80%;
        height: auto;
        align-self: center;
    }

    .first-text-about {
        font-size: 5vw;
        margin-bottom: 0%;
        line-height: 6vw;
    }
    
    .second-text-about {
        font-size: 3vw;
        margin-top: 4%;
        line-height: 4vw;
        text-align: justify;
    }
}