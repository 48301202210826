.product-component{
    text-align: center;
    width: 30%;
}

.product-logo{
    width: 100%;
    height: auto;
}

.product-name{
    width: 100%;
    color : #DB3147;
    font-style: normal;
    font-size: 1.2vw;
    margin-bottom: 0%;
    margin-top: 0%;
    font-weight:500;
}

.product-title{
    width: 100%;
    color: black;
    font-style: normal;
    font-size: 2vw;
    margin-top: 0%;
    margin-bottom: 0%;
    font-weight: bold;
}

.product-title:hover{
    color:blue;
}

.product-description{
    width: 100%;
    font-size: 1vw;
    margin-top: 0%;
    font-weight:500;
}


@media only screen and (max-width:601px) {
    .product-component{
        width: 50%;
    }
    
    .product-name{
        font-size: 3vw;
    }

    .product-title{
        font-size: 4vw;
    }

    .product-description{
        font-size: 2.5vw;
    }
}