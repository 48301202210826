.footer{
    background: #242830;
    color: white;
    padding: 8%;
    text-align: center;
}

.footer-image{
    width: 40%;
    height: auto;
}

.footer-icon-div{
    align-items: center;
    width: 100%;
}

.footer-icon{
    width: 10%;
    padding-right: 5%;
}

.footer-heading{
    font-size: 2vw;
    margin-top: 0%;
    margin-bottom: 0%;
}

.footer-text{
    font-size: 1.5vw;
}

.footer-list{
    list-style: none;
    justify-content: left;
    padding: 0%;
    font-size: 1.5vw;
}

.nav-item {
    color: white;
    margin-top: 5%;
    padding: 0;
    text-decoration: none;
    font-size: 1.5vw;
}

.nav-item-clickable {
    color: white;
    margin-top: 5%;
    padding: 0;
    text-decoration: none;
    font-size: 1.5vw;
}

.footer-div{
    width: 25%;
}

.nav-item-clickable:hover {
    color:blue;
}

@media only screen and (max-width:601px) {
    
    .footer-heading{
        font-size: 2.5vw;
    }

    .footer-text{
        font-size: 2vw;
    }

    .footer-list{
        font-size: 2vw;
    }

    .nav-item {
        font-size: 2vw;
    }

    .nav-item-clickable {
        font-size: 2vw;
    }
    
}