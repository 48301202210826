.table-of-content-component{
    margin: 0;
    padding: 0;
    text-align: left;
}

.table-of-content-component :hover {
    background-color: rgb(77, 77, 77);
}

.table-of-content-component-text{
    font-size: 1.1vw;
    margin: 0;
    padding: 4%;
}

hr{
    margin: 0;
    border: 0.5px solid rgb(196, 194, 194 , 0.5);
}