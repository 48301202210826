/* header css  */

.fixed-header {
    position: fixed;
    width: 100%;
    height: auto;
    background: #242830;
    color: white;
    padding: 1%; 
    z-index: 1000;
}

.header-logo {
    width: 4%;
    height: 4%;
    flex-shrink: 0;
    align-self: center;
}

  /* Navbar.css */

.navbar {
    width:100%;
    height: auto;
}

.nav-list {
    list-style: none;
    display: flex;
    justify-content:right;
    padding-right: 4.3%;
    margin:0;
}

.nav-item-header {
    width: fit-content !important;
    color: white;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    font-size: 1.5vw;
}

.nav-item-header:hover {
    color: #28C3FF;
}


.drawer {
    width: 30%;
}

.navbar-small-device{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left :7%;
    padding-right: 8%;
}

.navbar-big-device{
    padding-left :7%;
    padding-right: 8%;
}

@media only screen and (max-width:901px){
    .nav-list{
        display: flex;
        flex-direction: column;
    }
    .nav-item-header{
        color: black;
    }

    .header-logo{
        width: 5%;
        height: 5%;
        margin-top: 3%;
    }
}

@media only screen and (max-width:500px){
    .header-logo{
        width: 7%;
        height: 7%;
        margin-top: 4%;
    }
}