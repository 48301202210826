.blog-chapter{
    text-align: justify;
    margin-top: 5%;
}

.blog-chapter-title{
    font-style: normal;
    font-size: 2vw;
    font-weight: 500;
    margin-bottom: 0%;
    letter-spacing: .05vw;
    margin-top: 0%;
}

.blog-chapter-content{
    font-style: normal;
    font-size: 1.2vw;
    font-weight: 100;
    margin-bottom: 0%;
    margin-top: 3%;
    letter-spacing: .1vw;
    line-height: 1.5vw;
}

.hidden {
    display: none;
}

.blog-chapter-img{
    height: 30%;
    width: fit-content;
    margin-top: 3%;
}

@media only screen and (max-width:601px) {
    .blog-chapter-img{
        height: auto;
        width: 100%;
        margin-top: 3%;
    }

    .blog-chapter-title{
        font-size: 4vw;
    }

    .blog-chapter-content{
        font-size: 3vw;
        line-height: 4vw;
    }
}