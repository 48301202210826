.service-component-div{
    width: 100%;
    height: auto;
    background-color: #F9FBFF;
    padding: 5%;
    margin-top: 5%;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    border-radius: 15px;
}

.service-component-div.flipped {
    color: #F9FBFF;
    background-color: #242830;
    transform: rotateY(180deg);
    border-radius: 15px;
}

.service-card-type-animation{
    background: linear-gradient(90deg, #48ffde, #4ae9ff , #fc3ef4 , #00ccf9 ); /* Define your gradient colors */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; /* Ensure text is transparent to show the gradient */
    background-clip: text;
    color: transparent; 
    font-style: normal;
    font-size: 1.5vw;
    font-weight: 400;
    line-height: 2.5vw;
}

.service-card-component-front,
.service-card-component-back {
    width: 100%;
    height: 100%;
    backface-visibility:hidden;
}

.service-card-component-back{
    white-space: pre-line;
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
}

.hidden{
    display: none;
}

.service-component-logo{
    width: 25%;
    height: 25%;
    margin-bottom: 2%;
}

.first-text-service-component{
    margin-top: 5%;
    font-size: 2vw;
}

.second-text-service-component{
    margin-top: 0;
    margin-top: 2%;
    font-size: 1.2vw;
}

.submit-btn {
    display: block;
    width: 100%;
    padding: 0.75rem;
    background-color:  #28C3FF;
    color: #FFF;
    font-size: 1rem;
    font-weight: 400;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    justify-content: center;
}

.submit-btn:hover {
    background-color:#FFFF;
    color: #242830;
}


.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 10px;                             /* Spacing between items */
    padding: 16px;
}

.image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.grid-image {
    width: 80%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.image-caption {
    margin-top: 8px;
    font-size: 14px;
    color: white;
}


@media only screen and (max-width:601px) {
    .service-component-div{
        width: 80%;
        align-self: center;
    }

    .service-component-logo{
        width: 15%;
        height: 15%;
        align-self: center;
    }

    .first-text-service-component{
        font-size: 4vw;
    }

    .second-text-service-component{
        font-size: 3vw;
    }

    .service-card-type-animation{
        font-size: 4vw;
        line-height: 6vw;
        text-align: left;
    }
}