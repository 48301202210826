.blog-list-component{
    background-color:#363A41;
    border-radius: 24px;
    padding: 8% 8% ;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: justify;
    margin-top: 3%;
}

.blog-list-img{
    width: 100%;
    height: auto;
}

.blog-list-title{
    font-style: normal;
    font-size: 1.5vw;
    margin-bottom: 0%;
    margin-top: 4%;
    font-weight: normal;
    text-align: left;
    letter-spacing: .1vw;
    line-height: 1.8vw;
}

.blog-list-subtitle{
    font-style: normal;
    font-size: 1.1vw;
    margin-bottom: 0%;
    margin-top: 4%;
    font-weight: 100;
    letter-spacing: .1vw;
    line-height: 1.5vw;
    text-align: left;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blog-list-bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-top: 6%;
    margin-bottom: 0%;
}

.blog-list-time{
    font-style: normal;
    font-size: 1vw;   
    font-weight: 100;
}

.blog-list-read-more{
    background-color: white;
    border: none;
    color: #242830;
    float: right;
    padding: 2% 4%;
    border-radius: 5% /50%;
    cursor: pointer;
    font-size: 1.2vw;
    transition: background-color 0.3s;
}

.blog-list-read-more:hover {
    background-color:  #28C3FF;
    color: white;
}

@media only screen and (max-width:601px) {
    .blog-list-title{
        font-size: 3.5vw;
        line-height: 4vw;
        text-align: left;
        letter-spacing: .2vw;
    }

    .blog-list-subtitle{
        text-align: left;
        font-size: 3vw;
        line-height: 4vw;
    }

    .blog-list-time{
        font-size: 2.4vw;    
    }

    .blog-list-read-more{
        font-size: 2.5vw;
    }
}