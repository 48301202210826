.service-page{
    display: flex;
    flex-direction:row ;
    gap: 10%;
    background: white;
    color: black;
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 5%;
    padding-top: 5%;
}

.service-page-para{
    width: 40%;
    height: auto;
    align-content: center;
}

.service-page-components{
    width: 50%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5% 10% ;
}

.service-page-decor {
    margin-left: 0;
}

.first-text-service{
    font-style: normal;
    font-size: 3.2vw;
    font-weight: bold
}

.second-text-service{
    font-style: normal;
    margin-top: 5%;
    font-size: 1.2vw;
    font-weight: 200;
    letter-spacing: .1vw;
    line-height: 1.5vw;
}

@media only screen and (max-width:601px) {
    .service-page{
        text-align: center;
        text-align: center;
        flex-direction: column-reverse;
    }

    .service-page-decor{
        display: none;
    }

    .service-page-components{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .service-page-para{
        width: 100%;
    }

    .first-text-service{
        font-size: 5vw;
        margin-bottom: 0%;
        line-height: 6vw;
    }
    
    .second-text-service{
        font-size: 3vw;
        margin-top: 4%;
        line-height: 4vw;
        text-align: justify;
    }
}