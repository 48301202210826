.blog-list-page{
    height: 100%;
    width: 100%;
}

.blog-list-top-and-grid{
    background: #242830;
    padding-left: 8%;
    padding-right: 7%;
    padding-top: 9%;
    color: white;
    text-align: center;
}

.blog-list-heading-img{
    width : 50%;
    height : auto;
    margin: 0;
    padding: 0;
}

.blog-list-combined-component{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3%;
}

.blog-list-coming-soon{
    height : 40%;
    background: #242830;
    color: white;
    padding-left: 8%;
    padding-right: 7%;
    padding-top: 13%;
    text-align: center;
}

@media only screen and (max-width:601px) {
    .blog-list-coming-soon{
        height: 65%;
    }

    .blog-list-top-and-grid{
        padding-left: 15% ;
        padding-right: 15%;
        padding-top: 16%;
        padding-bottom: 2%;
    }

    .blog-list-combined-component{
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        gap: 1%;
    }

    .blog-list-heading-img{
        width : 100%;
    }
}